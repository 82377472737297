import { useEffect, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { getUserDataSelf } from '../../data/services/apiUser'
import { getUserTransactions } from '../../data/services/apiTransaction'
import { createTransaction } from '../../data/services/apiTransaction'
import { forceInputNumber } from '../../utils/functions/forceInput'
import '../../style/UI/Transaction.scss'

export default function Transaction() {
  const [user, setUser] = useState([])
  const [transactions, setTransactions] = useState([])
  const [errorMsg, setErrorMsg] = useState('')
  const [inputErrors, setInputErrors] = useState({})
  const [openTransactions, setOpenTransactions] = useState(false)
  const [account, setAccount] = useState('')
  const [name, setName] = useState('')
  const [amount, setAmount] = useState(0)
  const [success, setSuccess] = useState(false)
  const location = useLocation()

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    try {
      let result
      result = await getUserDataSelf()
      setUser(result)
      result = await getUserTransactions()
      setTransactions(result)
    } catch (error) {
      setErrorMsg(error.message)
    }
  }

  const handleChangeAccount = (e) => setAccount(e.target.value.substring(0, 24))
  const handleChangeName = (e) => setName(e.target.value.substring(0, 30))
  const handleChangeAmount = (e) => setAmount(forceInputNumber(amount, e.target.value, 7))

  function handleOpenTransactions() {
    if (openTransactions === false) {
      setSuccess(false)
    }
    setOpenTransactions(!openTransactions)
  }

  function canSubmit() {
    const errors = {
      account: '',
      name: '',
      amount: '',
    }
    if (account === '') {
      errors.account = 'Ce champ ne doit pas être vide'
    }
    if (name === '') {
      errors.name = 'Ce champ ne doit pas être vide'
    }
    if (amount <= 0) {
      errors.amount = 'Le montant doit être supérieur à zéro'
    }
    setInputErrors(errors)
    let success = Object.values(errors).every((el) => el.length === 0) // passe toutes les valeurs en vérifiant que ce sont des bien chaînes de caractère vides (bouléen vrai)
    return success
  }

  async function handleSubmit(e) {
    e.preventDefault()
    if (!canSubmit()) return
    try {
      await createTransaction({ to: account, name, amount: +amount, code: '' })
      setSuccess(true)
      setAccount('')
      setName('')
      setAmount(0)
      setOpenTransactions(false)
      setTimeout(() => {
        window.location.reload()
      }, 1100)
    } catch (error) {
      throw error.messsage
    }
  }

  return (
    <div>
      {/* <h3 className="text-align-c">Envoyer un paiement à un tiers</h3> */}
      <div>
        <button onClick={handleOpenTransactions} className="transa-btn">
          {openTransactions ? (
            <span>Annuler le paiement en cours</span>
          ) : (
            <span>Envoyer un paiement</span>
          )}
        </button>
      </div>

      {openTransactions && (
        <form className="transa-form">
          <label>Identifiant du compte à créditer</label>
          <input value={account} onChange={handleChangeAccount} />
          {inputErrors.account && <p className=" text-align-ce">{inputErrors.account}</p>}
          <label>Libellé du virement</label>
          <input value={name} onChange={handleChangeName} />
          {inputErrors.name && <p className=" text-align-ce">{inputErrors.name}</p>}
          <label>Montant à émettre</label>
          <input value={amount} onChange={handleChangeAmount} />
          {inputErrors.amount && <p className=" text-align-ce">{inputErrors.amount}</p>}
          {/* {inputErrors.amount ||
            (amount <= 0 && <p className=" text-align-ce">{inputErrors.amount}</p>)} */}
          <button onClick={handleSubmit} className="transa-btn">
            Validation
          </button>
        </form>
      )}
      {success && <p className="text-align-c green">Votre don d'argent a bien été effectué</p>}
    </div>
  )
}
