import { ranks } from '../../data/constants/constants'

export default function RenderFormUser({
  handleSubmit,
  surname,
  name,
  accountNumber,
  password,
  // setPassword,
  // changePassword,
  // setChangePassword,
  birthday,
  ss,
  balance,
  id,
  level,
  phoneNumber,
  handleChangeName,
  handleChangeSurname,
  handleChangeAccountNumber,
  handleChangePassword,
  handleChangeBirthday,
  handleChangeBalance,
  handleChangeSs,
  handleChangePhone,
  handleChangeLevel,
  inputErrors,
  isAdmin,
  MdGppGood,
}) {
  return (
    <form onSubmit={handleSubmit}>
      {/* name et surname inversé en BDD */}
      <label>Nom</label>
      <input value={name.toUpperCase()} onChange={handleChangeName} />
      {inputErrors.name && <p className="errors-msg">{inputErrors.name}</p>}

      <label>Prénom</label>
      <input value={surname} onChange={handleChangeSurname} />
      {inputErrors.surname && <p className="errors-msg">{inputErrors.surname}</p>}

      {/* POST */}
      <label>Numéro du compte</label>
      <input
        value={accountNumber}
        onChange={handleChangeAccountNumber}
        disabled={!!id}
        placeholder=""
      />
      {inputErrors.accountNumber && <p className="errors-msg">{inputErrors.accountNumber}</p>}

      {!id && (
        <>
          <label>Mot de passe</label>
          <input type="text" value={password} placeholder="" onChange={handleChangePassword} />
          {inputErrors.password && <p className="errors-msg">{inputErrors.password}</p>}
        </>
      )}

      {/* <label>Mot de passe</label>
      {(!id || changePassword) && (
        <>
          {!!id && (
            <p style={{ color: '#ffd700', fontSize: '14px', padding: '0.3rem' }}>
              Veuillez entrer un nouveau mot de passe
            </p>
          )}
          <input type="text" value={password} placeholder="" onChange={handleChangePassword} />
          {inputErrors.password && <p className="errors-msg">{inputErrors.password}</p>}
        </>
      )}
      {!!id && (
        <div className="btn-flex" style={{ display: 'flex' }}>
          {!changePassword ? (
            <button
              className="change-btn"
              onClick={(e) => {
                e.preventDefault()
                setChangePassword(true)
              }}
            >
              Modifier
            </button>
          ) : (
            <button
              className="change-btn change-btn-unblocked"
              style={{ marginTop: '0.5rem' }}
              onClick={(e) => {
                e.preventDefault()
                setChangePassword(false)
                setPassword('')
              }}
            >
              Annuler
            </button>
          )}
        </div>
      )} */}

      {/* FORMATAGE VISUEL? */}
      <label>Sécurité sociale</label>
      <input value={ss} onChange={handleChangeSs} />
      {inputErrors.ss && <p className="errors-msg">{inputErrors.ss}</p>}

      <label>Téléphone</label>
      <input value={'+' + phoneNumber} onChange={handleChangePhone} />
      {inputErrors.phoneNumber && <p className="errors-msg">{inputErrors.phoneNumber}</p>}

      <label>Date de naissance</label>
      <input type="date" value={birthday} onChange={handleChangeBirthday} />
      {inputErrors.birthday && <p className="errors-msg">{inputErrors.birthday}</p>}

      {/* {isAdmin.toString()} */}
      {isAdmin && (
        <>
          <label>Rang</label>
          <select value={level} onChange={handleChangeLevel} className="select-level">
            <option value={ranks.USER}>USER</option>
            <option value={ranks.LOCAL_ADMIN}>LOCAL_ADMIN</option>
            <option value={ranks.ADMIN}>ADMIN</option>
          </select>
          {inputErrors.level && <p className="errors-msg">{inputErrors.level}</p>}
        </>
      )}

      {/* <label>Information</label>
      <textarea rows="5" placeholder="Votre message" value={info} onChange={handleChangeInfo} /> */}

      <label>Montant</label>
      <input value={balance} onChange={handleChangeBalance} />
      {inputErrors.balance && <p className="errors-msg">{inputErrors.balance}</p>}

      <button className="valid-btn">
        <p>{id ? 'Modifier' : 'Créer'}</p>
        <MdGppGood />
      </button>
    </form>
  )
}
