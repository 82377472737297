import '../../style/screens/Money.scss'

export default function Money() {
  return (
    <>
      <h2>Nos billets</h2>
      <main className="money-page">
        <div className="texts-blocs">
          <div className="two-grids">
            <div className="card-grid">
              <h4>Clovis 1er</h4>
              <img
                src={process.env.PUBLIC_URL + '/images/money/1000_FL_Clovis.png'}
                alt="monnaie, billet, histoire, france, art, identite, roi, franc"
              />
            </div>

            <div className="card-grid">
              <h4>Louis IX, saint Louis</h4>
              <img
                src={process.env.PUBLIC_URL + '/images/money/500_FL_St_Louis.png'}
                alt="monnaie, billet, histoire, france, art, identite, saint, roi"
              />
            </div>

            <div className="card-grid">
              <h4>Sainte Jéhanne d'Arc</h4>
              <img
                src={process.env.PUBLIC_URL + '/images/money/250_FL_J_dArc.png'}
                alt="monnaie, billet, histoire, france, art, identite, sainte"
              />
            </div>

            <div className="card-grid">
              <h4>Napoléon Bonaparte</h4>
              <img
                src={process.env.PUBLIC_URL + '/images/money/100_FL_Napoleon.png'}
                alt="monnaie, billet, histoire, france, art, identite, empereur"
              />
            </div>

            <div className="card-grid">
              <h4>Charles de Gaulle</h4>
              <img
                src={process.env.PUBLIC_URL + '/images/money/50_FL_De_Gaulle.png'}
                alt="monnaie, billet, histoire, france, art, identite, resistance, president"
              />
            </div>

            <div className="card-grid">
              <h4>Jean Jaurès</h4>
              <img
                src={process.env.PUBLIC_URL + '/images/money/20_FL_J_Jaures.png'}
                alt="monnaie, billet, histoire, france, art, identite, socialisme, patriotisme"
              />
            </div>

            <div className="card-grid">
              <h4>Jean Moulin</h4>
              <img
                src={process.env.PUBLIC_URL + '/images/money/10_FL_J_Moulin.png'}
                alt="monnaie, billet, histoire, france, art, identite, resistance"
              />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
