import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import '../../style/UI/BurgerMenu.scss'

export function useBurgerMenu() {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()

  useEffect(() => {
    // Menu se ferme au changement de chemin
    setIsOpen(false)
  }, [location.pathname])

  function toggle(e) {
    e.preventDefault()
    setIsOpen(!isOpen)
  }

  return { isOpen, setIsOpen, toggle }
}

export default function BurgerMenu({ children, isOpen = false }) {
  return <div className={`burger-container ${isOpen ? 'open' : 'close'}`}>{children}</div>
}
