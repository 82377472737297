import { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { getUserList, deleteUserAccount } from '../../data/services/apiUser'
import {
  getUserSearchByName,
  getUserSearchByAccount,
  getUserSearchByPhone,
} from '../../data/services/apiSearch'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import { MdOutlineCreate } from 'react-icons/md'
import {
  AuthContext,
  getTokenIsAdmin,
  getTokenIsLocalAdmin,
} from '../../utils/providers/AuthContext'
import RenderUser from '../../utils/renders/RenderUser'
import queryString from 'query-string'
import Pagination from '../UI/Pagination'
import UserSearch from '../UI/UserSearch'
import LoadingSpinner from '../UI/LoadingSpinner'
import '../../style/screens/UserList.scss'

const PER_PAGE = 50

export default function UserList() {
  const { isConnected, logOutErrorHandler } = useContext(AuthContext)
  const { pageLoading, setPageLoading } = usePageAuth()
  const [users, setUsers] = useState([])
  const [total, setTotal] = useState(0)
  const [errorMsg, setErrorMsg] = useState('')
  const [, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { page = 1, searchCategory, searchValue } = queryString.parse(location.search)
  // const [page, setPage] = useState(1)
  // const [isBlocked, setIsBlocked] = useState(false)
  // const [deleteAccount, setDeleteAccount] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [isAdmin] = useState(getTokenIsAdmin())

  useEffect(() => {
    initialCheck() // Vérifie le rang et recharge la liste lors de la navigation (voir plus bas)
  }, [location.search])

  async function initialCheck() {
    setPageLoading(true)
    const isAllowed = getTokenIsAdmin() || getTokenIsLocalAdmin()

    if (isAllowed) {
      await fetchUsers()
    } else if (isConnected) {
      setErrorMsg('Accès non autorisé.')
      setPageLoading(false)
    }
  }

  async function fetchUsers() {
    setPageLoading(true)
    try {
      let result
      switch (searchCategory) {
        case 'NAME':
          result = await getUserSearchByName(searchValue.toUpperCase())
          setUsers(result)
          setTotal(result.length) // temporary without pagination
          break
        case 'ACCOUNT':
          result = await getUserSearchByAccount(searchValue)
          setUsers(result)
          setTotal(result.length)
          break
        case 'PHONE':
          result = await getUserSearchByPhone(searchValue)
          setUsers(result)
          setTotal(result.length)
          break

        default:
          result = await getUserList(page - 1, PER_PAGE)
          setUsers(result.data)
          setTotal(result.total)
          break
      }
      // getUser
    } catch (error) {
      setErrorMsg(error.message)
    } finally {
      setPageLoading(false)
    }
  }

  function setUrlParams(params) {
    const query = queryString.parse(location.search) // analyse paramètre url
    Object.entries(params).forEach(([key, value]) => {
      query[key] = value
    })
    setSearchParams(query)
  }

  function handlePageError() {
    const isAllowed = getTokenIsAdmin() || getTokenIsLocalAdmin()
    if (isAllowed) {
      setErrorMsg('')
    } else {
      navigate('/compte')
    }
  }

  function handleChangePage(n) {
    if (n != page) {
      setUrlParams({ page: n })
    }
  }

  function handleSearch({ searchCategory, searchValue }) {
    setUrlParams({ page: 1, searchCategory, searchValue: searchValue.toUpperCase() })
  }

  function handleResetSearch() {
    navigate(location.pathname)
  }

  // const handleBlocked = () => setIsBlocked(!isBlocked)

  async function handleDeleteAccount(user) {
    try {
      const response = await deleteUserAccount(user.id)
      if (response.status === 200) {
        setSuccessMsg(`L'utilisateur ${user.name} ${user.surname} a été supprimé`)
        setTimeout(() => {
          fetchUsers()
          setSuccessMsg('')
        }, 3000)
      }
    } catch (error) {
      setErrorMsg('Une erreur est survenue')
    }
  }
  // const handleDeleteAccount = () => console.log('click on deleteAccount')

  function renderTable() {
    return (
      <div className="table-container">
        {users.length > 0 ? (
          <table>
            <tbody>
              <tr>
                <th>#</th>
                <th>ID</th>
                <th>Nom</th>
                <th>Prénom</th>
                <th className="hide-responsive">Rang</th>
                {/* <th className="hide-responsive">Info</th> */}
                <th className="hide-responsive">Anniversaire</th>
                <th className="hide-responsive">Téléphone</th>
                <th className="hide-responsive">SS</th>
                <th className="hide-responsive">Balance</th>
                <th>Action</th>
              </tr>

              {users.map((user, index) => {
                return (
                  <RenderUser
                    key={user.id}
                    user={user}
                    index={index}
                    page={page}
                    perPage={PER_PAGE}
                    onDelete={handleDeleteAccount}
                  />
                )
              })}
            </tbody>
          </table>
        ) : (
          <p className="msg-no-account">Aucun compte ne correspond à la recherche</p>
        )}
      </div>
    )
  }

  return pageLoading ? (
    <main className="page-msg">
      <LoadingSpinner />
    </main>
  ) : errorMsg ? (
    <main className="page-msg">
      <p>{errorMsg}</p>
      <button className="back-button-edit" onClick={handlePageError}>
        Retour
      </button>
    </main>
  ) : successMsg ? (
    <main className="page-msg">
      <p>{successMsg}</p>
    </main>
  ) : (
    <>
      <h2>Nos utilisateurs</h2>
      <main className="personnal-profile">
        <div className="table-titles">
          {isAdmin && (
            <Link to={`/utilisateur`}>
              <button
                className="create-btn"
                // disabled={!isAdmin}
              >
                <p>Création</p>
                <MdOutlineCreate />
              </button>
            </Link>
          )}

          {users.length > 0 && (
            <Pagination onClick={handleChangePage} page={page} total={total} perPage={PER_PAGE} />
          )}
          <UserSearch onSubmit={handleSearch} onReset={handleResetSearch} />
          {renderTable()}

          {users.length > 0 && (
            <Pagination onClick={handleChangePage} page={page} total={total} perPage={PER_PAGE} />
          )}
        </div>
      </main>
    </>
  )
}
