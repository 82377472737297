import { useEffect, useState, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import { getUserDataSelf } from '../../data/services/apiUser'
import { AuthContext, getTokenIsLocalAdmin } from '../../utils/providers/AuthContext'
import HelpContainer from '../UI/HelpContainer'
import LoadingSpinner from '../UI/LoadingSpinner'
import { timestampToDate, formatDate } from '../../utils/functions/timeDate'
import '../../style/screens/Account.scss'

export default function Account() {
  const [errorMsg, setErrorMsg] = useState('')
  const [reject, setReject] = useState(false)
  const { logOutErrorHandler } = useContext(AuthContext)
  const [user, setUser] = useState(null)
  const { pageLoading, setPageLoading } = usePageAuth()
  const navigate = useNavigate()
  // Message copy
  const [msgCopySuccess, setMsgCopy] = useState('')
  const [copySuccess, setCopySuccess] = useState(false)
  const textAreaRef = useRef(null)

  useEffect(() => {
    async function fetchUser() {
      setPageLoading(true)
      try {
        // result.ss = null // TEST TEMPORARY // setExchangeRate(exange)
        const result = await getUserDataSelf()
        setUser(result)
      } catch (error) {
        if (logOutErrorHandler(error)) {
          return
        }
        setErrorMsg(error.message)
      }
      setPageLoading(false)
    } // Récupération des données user

    if (getTokenIsLocalAdmin()) {
      setErrorMsg("Vous n'êtes pas autorisé")
      setReject(true)
    } else {
      fetchUser()
    }
  }, [])

  function copyToClipboard(e) {
    textAreaRef.current.select() // contenteditable
    document.execCommand('copy')
    e.target.focus()
    setCopySuccess(true)
    setMsgCopy('COPIÉ !!!')
    setTimeout(() => {
      setCopySuccess(false)
    }, 900)
  }

  function handleListener() {
    console.log('Copy')
  }

  return pageLoading ? (
    <main className="page-msg">
      <LoadingSpinner />
    </main>
  ) : errorMsg ? (
    <main className="page-msg">
      <p>{errorMsg}</p>
      <button
        className="back-button-edit"
        onClick={() => {
          if (reject) {
            navigate('/liste-utilisateurs')
          } else {
            setErrorMsg('')
          }
        }}
      >
        Retour
      </button>
    </main>
  ) : (
    <>
      <HelpContainer
        links={[
          {
            path: '/operation',
            img: process.env.PUBLIC_URL + '/images/icons/bleu_mar_curseurs.png',
            text: 'Mes opérations',
          },
          {
            path: '/beneficiaires',
            img: process.env.PUBLIC_URL + '/images/icons/bleu_mar_compte.png',
            text: 'Mes bénéficiaires',
          },
        ]}
      />
      <main className="personnal-space">
        <div className="texts-blocs">
          <h2>MON COMPTE</h2>
          <div className="saving-account">
            <h3 className="h-subtitle">Livret actuel :</h3>
            <div className="saving-money">
              <div className="saving-flex">
                <img
                  src={process.env.PUBLIC_URL + '/images/icons/bleu_mar_soleil.png'}
                  className="saving-sun"
                  height="30px"
                  alt="soleil, montant, nom utilisateur"
                />
                <div className="started-mobile">
                  <p>{user.name + ' ' + user.surname}</p>
                  <span className="saving-bolded">Compte de dépôt</span>
                  <span> N°{user.accountNumber}</span>
                </div>
              </div>
              <p className="saving-bolded saving-position">{user.balance} fr.</p>
            </div>
          </div>

          <div className="saving-account">
            <h3 className="h-subtitle">Mes coordonnées :</h3>
            <div className="flex-user">
              <div className="flex-id">
                <p className="text-styled">Votre identifiant</p>
                <button className="account-btn" onClick={copyToClipboard}>
                  {copySuccess ? msgCopySuccess : <span>COPIER</span>}
                </button>
              </div>

              <p className="text-styled">
                Date d'anniversaire : {formatDate(timestampToDate(user.birthday), 'D/M/YY')}
              </p>
              {user.ss?.length > 0 && (
                <p className="text-styled">N° de sécurité sociale : {user.ss}</p>
              )}
              {user.phoneNumber?.length > 0 && (
                <p className="text-styled">N° de téléphone : {user.phoneNumber}</p>
              )}
              <p className="text-styled">Nombres de bénéficiaires : {user.contacts?.length ?? 0}</p>
              {/* {user.level?.length > 0 &&  <p className="text-styled">Rang : {user.level}</p>} */}
            </div>
          </div>
        </div>
        <textarea
          className="textarea-none"
          ref={textAreaRef}
          value={user.id}
          onChange={handleListener}
        />
      </main>
      <HelpContainer
        links={[
          {
            path: '/enseignes',
            img: process.env.PUBLIC_URL + '/images/icons/bleu_mar_in_situ.png',
            text: 'Où utiliser les francs libres ?',
          },
          {
            path: '/contact',
            img: process.env.PUBLIC_URL + '/images/icons/bleu_mar_hotline.png',
            text: "Besoin d'aide ? Contactez-nous",
          },
        ]}
      />
    </>
  )
}
