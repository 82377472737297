import { baseUrl } from '../constants/constants'
import axios from 'axios'

export const getUserSearchByName = async (name) => {
  try {
    const accountName = await axios.get(`${baseUrl}api/admin/accountsByName/?name=${name}`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    }) // Nom de famille
    return accountName.data
  } catch (error) {
    throw error
  }
}

export const getUserSearchByAccount = async (id) => {
  try {
    const accountNumber = await axios.get(`${baseUrl}api/admin/accountsByAccountNumber/?id=${id}`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    }) // Compte à 8 chiffres
    return accountNumber.data
  } catch (error) {
    throw error
  }
}

export const getUserSearchByPhone = async (phone) => {
  try {
    const accountPhone = await axios.get(
      `${baseUrl}api/admin/accountsByPhoneNumber/?phoneNumber=${phone}`,
      {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
      }
    )
    return accountPhone.data
  } catch (error) {
    throw error
  }
}
