import '../../style/UI/LoadingSpinner.scss'

export default function LoadingSpinner() {
  return (
    <div className="loading-container">
      <div className="loading"></div>
      <div id="loading-text">Chargement...</div>
    </div>
  )
}
