import { baseUrl } from '../constants/constants'
import axios from 'axios'
import User from '../models/User.ts'

export const getUserDataAdmin = async (id) => {
  try {
    const response = await axios.get(`${baseUrl}api/admin/account/${id}/`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    })
    return new User(response.data)
  } catch (error) {
    throw error
  }
}

export const getUserDataSelf = async () => {
  try {
    const response = await axios.get(`${baseUrl}api/account/`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    })
    return new User(response.data)
  } catch (error) {
    if (error.response.status === 403) {
      throw new Error('TOKEN_EXPIRED')
    } else {
      throw error
    }
  }
}

// export const getUserLevel = async (id) => {
//   try {
//     const response = await axios.get(`${baseUrl}api/account/${id}/`, {
//       headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
//     })
//     return response
//   } catch (error) {
//     throw error
//   }
// }

export const getUserContact = async (id) => {
  try {
    const response = await axios.get(`${baseUrl}api/contact/${id}`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getUserList = async (page = 1, size = 50) => {
  try {
    const response = await axios.get(`${baseUrl}api/admin/accounts/?page=${page}&size=${size}`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    })
    const total = await axios.get(`${baseUrl}api/admin/accounts/count/`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    }) // get total pagination
    return { data: response.data, total: total.data }
  } catch (error) {
    throw error
  }
} // afficher, trier

// POST & PUT & Delete
export const loginUser = async (accountNumber, password) => {
  try {
    const response = await axios.post(`${baseUrl}api/login`, { accountNumber, password })
    return response.headers.authorization.split(' ')[1]
  } catch (error) {
    throw error
  }
} // POST at connexion

export const createUser = async (body) => {
  try {
    const response = await axios.post(`${baseUrl}api/admin/accounts/`, body, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    })
    if (response.data.error) {
      console.log('Erreur envoyée par le serveur', response.data.error)
    }
    return response
  } catch (error) {
    throw error
  }
} // POST

export const changeUserAccount = async (id, body) => {
  try {
    const response = await axios.put(`${baseUrl}api/admin/accounts/${id}/`, body, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    })
    return response
  } catch (error) {
    throw error
  }
} // PUT

export const deleteUserAccount = async (id) => {
  try {
    const response = await axios.delete(`${baseUrl}api/admin/account/${id}/`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    })
    return response
  } catch (error) {
    throw error
  }
} // DELETE
