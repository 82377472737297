export default function RenderBeneficiaryState({
  name,
  handleChangeName,
  id,
  handleChangeId,
  handleSubmitAdd,
  handleReset,
  handleSoftReset,
  states,
  currentState,
  inputErrors,
  sendError,
  handleToggleAdd,
  handleToggleSend,
  memName,
  memId,
  amount,
  transferDetails,
  handleChangeDetails,
  handleChangeAmount,
  handleSubmitSend,
}) {
  return (
    <>
      {currentState === states.ADD_START && (
        <>
          <h3 className="h3-profitable">Nouveau bénéficiaire</h3>
          <form className="profitable-form">
            <button onClick={handleToggleAdd} className="button-active profitable-btn">
              Annuler
            </button>
            <label>Nom du bénéficiaire</label>
            <input value={name} onChange={handleChangeName} />
            {inputErrors.name && <p className="text-align-ce">{inputErrors.name}</p>}
            <label>Identifiant du bénéficiaire</label>
            <input value={id} onChange={handleChangeId} />
            {inputErrors.id && <p className="text-align-ce">{inputErrors.id}</p>}
            <button onClick={handleSubmitAdd} className="profitable-btn">
              Validation
            </button>
            <p className="text-align-ce">{sendError}</p>
          </form>
        </>
      )}
      {currentState === states.ADD_SUCCESS && (
        <div>
          <p className="text-align-c">Bénéficiaire ajouté avec succès</p>
          <button className="button-active profitable-btn text-align-c" onClick={handleReset}>
            Retour
          </button>
        </div>
      )}
      {currentState === states.DELETE_SUCCESS && (
        <div>
          <p className="text-align-c">Bénéficiaire supprimé avec succès</p>
          <button className="button-active profitable-btn text-align-c" onClick={handleReset}>
            Retour
          </button>
        </div>
      )}
      {currentState === states.DELETE_FAIL && (
        <div>
          <p className="text-align-c">Une erreur est survenue</p>
          <button className="button-active profitable-btn text-align-c" onClick={handleSoftReset}>
            Retour
          </button>
        </div>
      )}
      {currentState === states.SEND_START && (
        <>
          <form className="profitable-form flex-column">
            <button onClick={handleToggleSend} className="button-active profitable-btn">
              Annuler
            </button>
            <label>Nom du bénéficiaire</label>
            <span className="profitable-btn red">{memName}</span>
            <label>Identifiant du bénéficiaire</label>
            <span className="profitable-btn red">{memId}</span>
            <label>Détails du transfert</label>
            <input value={transferDetails} onChange={handleChangeDetails} />
            {inputErrors.transferDetails && (
              <p className="text-align-ce">{inputErrors.transferDetails}</p>
            )}
            <label>Montant</label>
            <input value={amount} onChange={handleChangeAmount} />
            {inputErrors.amount && <p className="text-align-ce">{inputErrors.amount}</p>}
            <button onClick={handleSubmitSend} className="profitable-btn">
              Validation
            </button>
          </form>
        </>
      )}
      {currentState === states.SEND_SUCCESS && (
        <div>
          <p className="text-align-c">Transfert réalisé avec succès</p>
          <button className="button-active profitable-btn text-align-c" onClick={handleReset}>
            Retour
          </button>
        </div>
      )}
    </>
  )
}
