import { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../utils/providers/AuthContext'
import { getShopList } from '../../data/services/apiShop'
import { getUserDataSelf } from '../../data/services/apiUser'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import LoadingSpinner from '../UI/LoadingSpinner'
import leaflet from 'leaflet'
import 'leaflet/dist/leaflet.css'
import '../../style/screens/Shop.scss'

const iconShop = new leaflet.Icon({
  iconUrl: process.env.PUBLIC_URL + '/images/icons/bleu_mar_in_situ_two.png',
  iconSize: new leaflet.Point(30, 37),
  className: null,
})

export default function Shop() {
  const { pageLoading, setPageLoading } = usePageAuth()
  const [errorMsg, setErrorMsg] = useState('')
  const [shops, setShops] = useState([])
  const position = [46.52, 2.4]
  const { logOutErrorHandler } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    async function fetchShops() {
      setPageLoading(true)
      try {
        await getUserDataSelf()
        const response = await getShopList()
        setShops(response)
      } catch (error) {
        console.log({ error })
        if (logOutErrorHandler(error)) {
          return
        }
        setErrorMsg(error.message)
      }
      setPageLoading(false)
    }
    fetchShops()
  }, [])

  return pageLoading ? (
    <main className="page-msg">
      <LoadingSpinner />
    </main>
  ) : errorMsg ? (
    <main className="page-msg">
      <p>{errorMsg}</p>
      <button className="back-button-edit" onClick={() => setErrorMsg('')}>
        Retour
      </button>
    </main>
  ) : (
    <>
      <h2>Nos enseignes</h2>
      <main className="shop-page">
        <div className="texts-blocs">
          <div className="shop-map-container">
            <MapContainer center={position} zoom={6} scrollWheelZoom={false}>
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {shops.map((shop) => {
                return (
                  <Marker key={shop.id} icon={iconShop} position={shop.position}>
                    <Popup>
                      <h3>{shop.name}</h3>
                      <div>{shop.info}</div>
                      <div>{shop.contactName}</div>
                      <div>{shop.phoneNumber}</div>
                      <div>{shop.email}</div>
                    </Popup>
                  </Marker>
                )
              })}
            </MapContainer>
          </div>
        </div>
      </main>
    </>
  )
}
