import { Link } from 'react-router-dom'
import '../../style/UI/HelpContainer.scss'

export default function HelpContainer({ links }) {
  return (
    <div className="help-container">
      {links.map((obj, index) => {
        return (
          <Link to={obj.path} key={index} className="home-box__card">
            <img src={obj.img} height="40px" alt="icone, telephone, carte" />
            <p>{obj.text}</p>
          </Link>
        )
      })}
    </div>
  )
}
