import { useContext } from 'react'
import { AuthContext } from '../../utils/providers/AuthContext'
import '../../style/layout/Footer.scss'

export default function Footer() {
  const { refreshSession } = useContext(AuthContext)

  return (
    <div>
      <footer onMouseMove={refreshSession}>
        <div className="grid-column">
          <h5>Banque de France Libre</h5>
          <div className="grid-column">
            <a href="https://www.le-franc-libre.fr/contact" rel="noreferrer" target="_blank">
              Prise de contact
            </a>
          </div>
        </div>
        <div className="grid-column">
          <h5>Communauté partenaire</h5>
          <div>
            <a href="https://www.le-franc-libre.fr" rel="noreferrer" target="_blank">
              Le Franc Libre
            </a>
            <a href="https://comitedesalutpublic.fr" rel="noreferrer" target="_blank">
              Comité du Salut du Peuple
            </a>
          </div>
        </div>
        <div className="grid-column">
          <div className="icon-link">
            <a
              className="icon-fl"
              href="https://www.le-franc-libre.fr"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={process.env.PUBLIC_URL + '/images/titles/logo-le-franc-libre.png'}
                width="160px"
                alt=""
              />
            </a>
          </div>
          <div className="icon-link">
            <a href="https://comitedesalutpublic.fr" rel="noreferrer" target="_blank">
              <img
                src={process.env.PUBLIC_URL + '/images/elements/comite-salut-public.png'}
                width="60px"
                alt="comite salut public, souverainete"
              />
            </a>
          </div>
        </div>
      </footer>
    </div>
  )
}
