import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthProvider } from './utils/providers/AuthContext'
import { SessionProvider } from './utils/providers/SessionProvider'
import Layout from './component/layout/Layout'
import Error from './component/layout/Error'
import Connection from './component/screens/Connection'
import Account from './component/screens/Account'
import Operation from './component/screens/Operation'
import Beneficiaries from './component/screens/Beneficiaries'
import UserEdit from './component/screens/UserEdit'
import UserList from './component/screens/UserList'
import Currency from './component/screens/Currency'
import Contact from './component/screens/Contact'
import Shop from './component/screens/Shop'
import './style/variable/Theme.scss'
import './App.scss'

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <SessionProvider>
          <Routes>
            <Route element={<Layout />}>
              {/* Users */}
              <Route index path="/" element={<Connection />} />
              <Route path="/compte" element={<Account />} />
              <Route path="/operation" element={<Operation />} />
              <Route path="/beneficiaires" element={<Beneficiaries />} />
              <Route path="/enseignes" element={<Shop />} />
              {/* Adds */}
              <Route path="/contact" element={<Contact />} />
              <Route path="/monnaie" element={<Currency />} />
              {/* Admins */}
              <Route path="/liste-utilisateurs" element={<UserList />} />
              <Route path="/utilisateur/" element={<UserEdit />} />
              <Route path="/utilisateur/:id" element={<UserEdit />} />
              {/* Errors */}
              <Route path="*" element={<Error />} />
            </Route>
          </Routes>
        </SessionProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}
