export default class User {
  id: number
  accountNumber: string
  adminOperations: null | {}
  name: string
  surname: string
  level: string // admin, local_admin or client
  birthday: Date
  email?: string
  phoneNumber: string
  info: any
  contacts: []
  ss: string // securité sociale
  balance: number // montant restant de franc_libre de l'utilisateur

  constructor(data: any) {
    this.id = data.id
    this.accountNumber = data.accountNumber
    this.adminOperations = data.adminOperations
    this.name = data.name
    this.surname = data.surname
    this.level = data.level
    this.birthday = data.birthday
    this.email = data.email
    this.phoneNumber = data.phoneNumber
    this.info = data.info
    this.contacts = data.contacts
    this.ss = data.ss
    this.balance = data.balance
  }
}
