import { baseUrl } from '../constants/constants'
import axios from 'axios'
import Transaction from '../models/Transaction.ts'

export const getUserTransactions = async () => {
  try {
    const response = await axios.get(`${baseUrl}api/account/transactions/`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    })
    return response.data.map((transactionData) => new Transaction(transactionData))
  } catch (error) {
    throw error
  }
} // User and transaction

export const createTransaction = async (body) => {
  try {
    const response = await axios.post(`${baseUrl}api/transaction/new/account/`, body, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    })
    if (response.error) {
      console.log('Erreur envoyée par le serveur', response.error)
    }
    return response //body : to:string (id du compte vers qui on envoi), name:string (titre de la transaction), amount:string/nombre (quantité qu'on envoi)
  } catch (error) {
    throw error
  }
} // POST

export const exchangeRate = async (body) => {
  try {
    const response = await axios.get(`${baseUrl}api/exchangeRate/`, body)
    return response.data //body : pour afficher le taux d'echange euro / franc libre j'ai créer une route : /api/exchangeRate/
  } catch (error) {
    throw error
  }
} // GET
