import { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import { getUserDataSelf } from '../../data/services/apiUser'
import { createBeneficiary, deleteBeneficiary } from '../../data/services/apiBeneficiary'
import { createTransaction } from '../../data/services/apiTransaction'
import { forceInputAlpha, forceInputNumber } from '../../utils/functions/forceInput'
import { AuthContext, getTokenIsLocalAdmin } from '../../utils/providers/AuthContext'
import LoadingSpinner from '../UI/LoadingSpinner'
import HelpContainer from '../UI/HelpContainer'
import RenderBeneficiaryState from '../../utils/renders/RenderBeneficiaryState'
import '../../style/screens/Beneficiaries.scss'

const states = {
  NONE: 0,
  ADD_START: 1,
  ADD_SUCCESS: 2,
  DELETE_SUCCESS: 3,
  DELETE_FAIL: 4,
  SEND_START: 5,
  SEND_SUCCESS: 6,
}

export default function Beneficiaries() {
  const { logOutErrorHandler } = useContext(AuthContext)
  const { pageLoading, setPageLoading } = usePageAuth()
  const [errorMsg, setErrorMsg] = useState('')
  const [reject, setReject] = useState(false)
  const [user, setUser] = useState([])
  const [name, setName] = useState('')
  const [id, setId] = useState('')
  const [memName, setMemName] = useState('')
  const [memId, setMemId] = useState('')
  const [transferDetails, setTransferDetails] = useState('')
  const [amount, setAmount] = useState(0)
  const [inputErrors, setInputErrors] = useState({})
  const [sendError, setSendError] = useState('')
  const [currentState, setCurrentState] = useState(states.NONE)
  const navigate = useNavigate()

  useEffect(() => {
    if (getTokenIsLocalAdmin()) {
      setErrorMsg("Vous n'êtes pas autorisé")
      setReject(true)
    } else {
      fetchData()
    }
  }, [])

  async function fetchData() {
    setPageLoading(true)
    try {
      let result
      result = await getUserDataSelf()
      setUser(result)
      // setBeneficiaries(result.contacts ?? [])
    } catch (error) {
      if (logOutErrorHandler(error)) {
        return
      }
      setErrorMsg(error.message)
    }
    setPageLoading(false)
  }

  function handleToggleAdd() {
    setName('')
    setId('')
    if (currentState === states.NONE) {
      setCurrentState(states.ADD_START)
    } else {
      setCurrentState(states.NONE)
    }
  }

  function handleToggleSend(e, id, name) {
    e.preventDefault()
    setMemName('')
    setMemId('')
    setAmount(0)
    setTransferDetails('')
    if (currentState === states.NONE) {
      setMemName(name)
      setMemId(id)
      setCurrentState(states.SEND_START)
    } else {
      setCurrentState(states.NONE)
    }
  }

  function handleReset(e) {
    e.preventDefault()
    fetchData()
    setCurrentState(states.NONE)
  }

  function handleSoftReset(e) {
    e.preventDefault()
    setCurrentState(states.NONE)
  }

  const handleChangeId = (e) => setId(e.target.value.substring(0, 24))
  const handleChangeName = (e) => setName(forceInputAlpha(name, e.target.value, " -'", 50))
  const handleChangeDetails = (e) => setTransferDetails(e.target.value)
  const handleChangeAmount = (e) => setAmount(forceInputNumber(amount, e.target.value, 7))

  function canSubmitAdd() {
    const errors = {
      name: '',
      id: '',
    }
    if (name === '') {
      errors.name = 'Ce champ ne doit pas être vide'
    }
    if (id === '') {
      errors.id = 'Ce champ ne doit pas être vide'
    }
    setInputErrors(errors)
    let success = Object.values(errors).every((el) => el.length === 0)
    return success
  }

  async function handleSubmitAdd(e) {
    e.preventDefault()
    if (!canSubmitAdd()) return
    setSendError('')

    try {
      await createBeneficiary({ name: name, id: id })
      setCurrentState(states.ADD_SUCCESS)
    } catch (error) {
      console.log(error)
      if (error.code === 'ERR_BAD_REQUEST') {
        setSendError('Identifiant invalide')
      } else {
        setSendError("Une erreur s'est produite")
      }
    }
  }

  function canSubmitSend() {
    const errors = {
      amount: '',
      transferDetails: '',
    }
    if (amount <= 0) {
      errors.amount = 'Le montant doit être supérieur à zéro'
    }
    if (transferDetails === '') {
      errors.transferDetails = 'Ce champ ne doit pas être vide'
    }
    setInputErrors(errors)
    let success = Object.values(errors).every((el) => el.length === 0)
    return success
  }

  async function handleSubmitSend(e) {
    e.preventDefault()
    if (!canSubmitSend()) return
    setSendError('')

    try {
      await createTransaction({
        to: memId,
        name: transferDetails,
        amount: +amount,
        code: '',
      })
      setCurrentState(states.SEND_SUCCESS)
    } catch (error) {
      console.log(error)
      if (error.code === 'ERR_BAD_REQUEST') {
        setSendError('Montant invalide')
      } else {
        setSendError("Une erreur s'est produite")
      }
    }
  }

  async function handleDelete(e, id) {
    e.preventDefault()
    if (window.confirm('Êtes-vous certain de vouloir supprimer cet utilisateur ?')) {
      try {
        await deleteBeneficiary(id)
        setCurrentState(states.DELETE_SUCCESS)
      } catch (error) {
        setCurrentState(states.DELETE_FAIL)
      }
    }
  }

  return pageLoading ? (
    <main className="page-msg">
      <LoadingSpinner />
    </main>
  ) : errorMsg ? (
    <main className="page-msg">
      <p>{errorMsg}</p>
      {/* <button
        className="text-align-ce back-button-edit"
        onClick={() => setErrorMsg('Une erreur est survenue')}
      >Retour</button> */}
      <button
        className="text-align-ce back-button-edit"
        onClick={() => {
          if (reject) {
            navigate('/liste-utilisateurs')
          } else {
            setErrorMsg('')
          }
        }}
      >
        Retour
      </button>
    </main>
  ) : (
    <>
      <HelpContainer
        links={[
          {
            img: process.env.PUBLIC_URL + '/images/icons/bleu_mar_synthese.png',
            text: 'Mon compte',
            path: '/compte',
          },
          {
            img: process.env.PUBLIC_URL + '/images/icons/bleu_mar_curseurs.png',
            text: 'Mes opérations',
            path: '/operation',
          },
        ]}
      />
      <main className="personnal-operation">
        <div className="texts-blocs">
          <h2 className="operation-span-text text-align-c">Mes bénéficiaires</h2>

          {currentState === states.NONE && (
            <div className="profitable-titles">
              <div className="profitable-name-id-action">
                <div className="profitable-name-id">
                  <p>Nom</p>
                  <p>ID</p>
                </div>
                <p className="profitable-action">Actions</p>
              </div>
            </div>
          )}

          {currentState === states.NONE && (
            <>
              <div>
                {user.contacts.length === 0 ? (
                  <div>
                    <p className="beneficiary-alert">Vous n'avez pas ajouté de bénéficiaire</p>
                  </div>
                ) : (
                  user.contacts.map((beneficiary, index) => {
                    // affiche 20 derniers bénéficiaires
                    if (index < 20) {
                      return (
                        <div key={index}>
                          <div className="profitable-container">
                            <div className="profitable-data">
                              <p className="profitable-padd">{beneficiary.name}</p>
                              <p>{beneficiary.id}</p>
                            </div>
                            <div className="profitable-btns">
                              <button
                                className="profitable-btn"
                                onClick={(e) =>
                                  handleToggleSend(e, beneficiary.id, beneficiary.name)
                                }
                              >
                                Envoi
                              </button>

                              <button
                                className="profitable-btn delete"
                                onClick={(e) => handleDelete(e, beneficiary.id)}
                              >
                                Suppression
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })
                )}
              </div>
              <h3 className="h3-profitable">Nouveau bénéficiaire</h3>
              <button onClick={handleToggleAdd} className="button-active profitable-btn">
                Ajouter un bénéficiaire
              </button>
            </>
          )}
          <RenderBeneficiaryState
            name={name}
            handleChangeName={handleChangeName}
            id={id}
            handleChangeId={handleChangeId}
            handleSubmitAdd={handleSubmitAdd}
            handleReset={handleReset}
            handleSoftReset={handleSoftReset}
            states={states}
            currentState={currentState}
            inputErrors={inputErrors}
            sendError={sendError}
            handleToggleAdd={handleToggleAdd}
            handleToggleSend={handleToggleSend}
            memName={memName}
            memId={memId}
            amount={amount}
            transferDetails={transferDetails}
            handleChangeDetails={handleChangeDetails}
            handleChangeAmount={handleChangeAmount}
            handleSubmitSend={handleSubmitSend}
          />
        </div>
      </main>
      <HelpContainer
        links={[
          {
            img: process.env.PUBLIC_URL + '/images/icons/bleu_mar_in_situ.png',
            text: 'Où utiliser les francs libres ?',
            path: '/enseignes',
          },
          {
            img: process.env.PUBLIC_URL + '/images/icons/bleu_mar_hotline.png',
            text: "Besoin d'aide ? Contactez-nous",
            path: '/contact',
          },
        ]}
      />
    </>
  )
}
