import { useState } from 'react'
import { MdOutlineCreate, MdOutlineDeleteOutline } from 'react-icons/md'
import { ImProfile } from 'react-icons/im'
import { Link } from 'react-router-dom'
import { formatDate, timestampToDate } from '../../utils/functions/timeDate'
import { getTokenIsAdmin, getTokenAccount } from '../providers/AuthContext'
import { jsPDF } from 'jspdf'

export default function RenderUser({ user, index, page, perPage, onDelete }) {
  const [isAdmin] = useState(getTokenIsAdmin())
  console.log({ user })

  function handleDeleteLocal(e, id) {
    e.preventDefault()
    if (
      window.confirm(
        `Êtes-vous certain de vouloir supprimer l'utilisateur suivant "${
          user.name + ' ' + user.surname
        }" ?`
      )
    ) {
      onDelete(user)
    }
  }

  function handleImpressionLocal(e) {
    e.preventDefault()
    try {
      const doc = new jsPDF()
      let x = 15
      let y = 20
      doc.setFontSize(14)
      doc.text(`Rapport pour ${user.name + ' ' + user.surname}`, x, y)
      y += 12

      const actionTexts = {
        create: 'Création de compte', //numéro de compte
        update: "Modification d'utilisateur", // id
        delete: "Suppression d'utilisateur", // id
      }
      doc.setFontSize(11, 5)
      let n = 0
      // console.log(user.adminOperations);
      if (user.adminOperations !== null) {
        for (const action of user.adminOperations) {
          // for (const action of [...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations, ...user.adminOperations]) {
          doc.text(
            `${formatDate(action.date, 'D/M/YY')} - ${actionTexts[action.action] ?? 'Action'}`,
            x,
            y
          )
          doc.text(
            `${['update', 'delete'].includes(action.action) ? 'ID: ' : 'N°: '}${action.target}`,
            190,
            y,
            { align: 'right' }
          )
          y += 8
          // Ajout de nouvelles pages
          n++
          if (n % 30 === 0) {
            doc.insertPage()
            y = 20
          }
        }
      } else {
        doc.text('Aucune opération recensée', x, y)
      }
      doc.save(`${user.name + '_' + user.surname}_${Date.now()}.pdf`) // save file
    } catch (error) {
      console.error(error.message)
    }
  }

  return (
    <>
      <tr key={user.id}>
        <th>{index + 1 + (page - 1) * perPage}</th>
        <td>{user.accountNumber}</td>
        <td>{user.name}</td>
        <td>{user.surname.toUpperCase()}</td>
        <td className="hide-responsive">{user.level}</td>
        {/* <td className="hide-responsive">{user.info}</td> */}
        <td className="hide-responsive">{formatDate(timestampToDate(user.birthday), 'D/M/YY')}</td>
        {/* <td>{user.email}</td> */}
        <td className="hide-responsive">{user.phoneNumber}</td>
        <td className="hide-responsive">{user.ss}</td>
        <td className="hide-responsive">{user.balance}</td>
        <td className="flex-actions">
          <Link to={`/utilisateur/${user.id}`}>
            <button
              className="change-btn"
              // className={`change-btn ${!isAdmin ? 'change-btn-disabled' : ''}`}
              // disabled={!isAdmin}
            >
              Modifier
              <MdOutlineCreate />
            </button>
          </Link>
          {/* <button
            className={`change-btn ${isBlocked ? 'change-btn-blocked' : 'change-btn-unblocked'}`}
            onClick={handleBlocked}
          >
            {isBlocked ? 'Débloq.' : 'Bloquer'}
            <MdOutlineBlock />
          </button> */}
          {isAdmin && (
            <button
              className="change-btn change-btn-unblocked"
              onClick={(e) => handleDeleteLocal(e, user.id)}
              // onClick={(e) => handleDeleteAccount(e, user.id)}
            >
              Suppr.
              <MdOutlineDeleteOutline />
            </button>
          )}
          {/* {isAdmin && user.level === 'ADMIN' && getTokenAccount ( */}
          {/* { (user.accountNumber === '99999998' || user.accountNumber === '54102783') && ( */}
          {[
            // '99999998',
            '54102783',
          ].includes(getTokenAccount()) &&
            user.level === 'ADMIN' && (
              <button
                className="change-btn change-btn-unblocked"
                style={{ backgroundColor: 'teal' }}
                onClick={handleImpressionLocal}
              >
                Imprimer
                <ImProfile />
              </button>
            )}
        </td>
      </tr>
    </>
  )
}
