import { FaTimesCircle } from 'react-icons/fa'

export default function RenderFormConnection({
  handleSubmit,
  handleChangeLogin,
  handleClickDigit,
  login,
  errorLogin,
  errorPassword,
  digits,
  letters,
  password,
  handleClearPassword,
}) {
  return (
    <form onSubmit={handleSubmit} className="form-container">
      <div className="input-container">
        <div className="input-container">
          <label className="bolded less-spacement">IDENTIFIANT</label>
          <p className="login-text-mobile">Saisissez votre identifiant</p>
        </div>
        <input
          className="input-login"
          type="text"
          value={login}
          onChange={handleChangeLogin}
          name="uname"
          required
        />
        <div className="display-msg">{errorLogin}</div>
      </div>

      <div className="input-container">
        <div className="input-container">
          <label className="bolded less-spacement">MOT DE PASSE</label>
          <p className="login-text-mobile">Saisissez votre mot de passe</p>
          <div className="array-numbers">
            {digits.map((n, index) => {
              return (
                <button key={index} name={letters[index]} onClick={handleClickDigit}>
                  {n}
                </button>
              )
            })}
          </div>
        </div>
        <div className="key-input">
          {[...Array(password.length).keys()].map((index) => {
            return (
              <span className="password-dot" key={index}>
                {' '}
              </span>
            )
          })}
          {password.length > 0 && (
            <button className="clear-btn" onClick={handleClearPassword}>
              <FaTimesCircle />
            </button>
          )}
        </div>
        <div className="display-msg">{errorPassword}</div>
      </div>
      <button className="button-submit">VALIDER</button>
    </form>
  )
}
