import { useEffect, useState } from 'react'
import { forceInputAlpha, forceInputNumber } from '../../utils/functions/forceInput'
import '../../style/UI/UserSearch.scss'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

export default function UserSearch({ onSubmit, onReset }) {
  const location = useLocation()
  const params = queryString.parse(location.search)
  // children
  const [searchCategory, setSearchCategory] = useState(params.searchCategory ?? 'NAME')
  const [searchName, setSearchName] = useState(
    params.searchCategory === 'NAME' ? params.searchValue ?? '' : ''
  )
  const [searchAccount, setSearchAccount] = useState(
    params.searchCategory === 'ACCOUNT' ? params.searchValue ?? '' : ''
  )
  const [searchPhone, setSearchPhone] = useState(
    params.searchCategory === 'PHONE' ? params.searchValue ?? '' : ''
  )
  const [searchValue, setSearchValue] = useState('')
  // console.log('a', searchValue)
  // console.log('b', params.searchValue)

  // Rediriger en fonction de la valeur de recherche
  useEffect(() => {
    const list = {
      NAME: searchName,
      ACCOUNT: searchAccount,
      PHONE: searchPhone,
    }
    setSearchValue(list[searchCategory] ?? '')
    // console.log(list[searchCategory])
  }, [searchCategory, searchName, searchAccount, searchPhone])

  const handleChangeCategory = (e) => setSearchCategory(e.target.value)

  // Input
  function handleChangeSearch(e) {
    e.preventDefault()
    if (searchCategory === 'NAME') {
      setSearchName(forceInputAlpha(searchName, e.target.value, " -'", 25))
    }
    if (searchCategory === 'ACCOUNT') {
      setSearchAccount(forceInputNumber(searchAccount, e.target.value, 8))
    }
    if (searchCategory === 'PHONE') {
      setSearchPhone(forceInputNumber(searchPhone, e.target.value.replace('+', ''), 12))
    }
  }

  // Buttons
  function handleSubmit(e) {
    e.preventDefault()
    if (searchValue.length > 0) {
      onSubmit({ searchCategory, searchValue }) // new canSearch
    }
  }

  function handleClear(e) {
    e.preventDefault()
    if (params?.searchValue?.length > 0) {
      onReset()
    }
    // }
  }

  return (
    <div className="search-container">
      <h4>Recherche :</h4>
      <form className="form-container" onSubmit={handleSubmit}>
        <div className="options-writing">
          <select value={searchCategory} onChange={handleChangeCategory}>
            <option value="NAME">NOM DE FAMILLE</option>
            <option value="ACCOUNT">N° de compte</option>
            <option value="PHONE">N° de téléphone</option>
          </select>
          {searchCategory === 'NAME' && (
            <input type="search" onChange={handleChangeSearch} value={searchName} />
          )}
          {searchCategory === 'ACCOUNT' && (
            <input type="search" onChange={handleChangeSearch} value={searchAccount} />
          )}
          {searchCategory === 'PHONE' && (
            <input type="search" onChange={handleChangeSearch} value={'+' + searchPhone} />
          )}
        </div>
        <div className="action">
          <button
            className="action-button"
            onClick={handleSubmit}
            disabled={searchValue.length === 0}
          >
            Rechercher
          </button>
          <button
            className="action-button reset-button"
            onClick={handleClear}
            disabled={params.searchValue ? params.searchValue.length === 0 : true}
          >
            Réinitialiser
          </button>
        </div>
      </form>
    </div>
  )
}
