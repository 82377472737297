import { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { refreshSession, SessionContext } from '../../utils/providers/SessionProvider'
import Header from './Header'
import Footer from './Footer'
import PopUpSessionTimeout from '../UI/PopUpSessionTimeout'

export default function Layout() {
  const { refreshCountdown } = useContext(SessionContext)

  return (
    <>
      {refreshCountdown != -1 && <PopUpSessionTimeout />}
      <Header />
      <div className="page-content" onMouseMove={refreshSession}>
        <Outlet />
      </div>
      <Footer />
    </>
  )
}
