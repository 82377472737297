import { useContext } from 'react'
import { getUserDataAdmin } from '../../data/services/apiUser'
import { createUser, changeUserAccount } from '../../data/services/apiUser'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { FiSkipBack } from 'react-icons/fi'
import { MdGppGood } from 'react-icons/md'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import { forceInput, forceInputAlpha, forceInputNumber } from '../../utils/functions/forceInput'
import { timestampToDate } from '../../utils/functions/timeDate'
import { ranks } from '../../data/constants/constants'
import { AuthContext, getTokenIsAdmin } from '../../utils/providers/AuthContext'
import isAlpha from 'validator/lib/isAlpha'
import RenderFormUser from '../../utils/renders/RenderFormUser'
import '../../style/screens/UserEdit.scss'

export default function UserEdit() {
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [password, setPassword] = useState('')
  const [changePassword, setChangePassword] = useState(false)
  const [ss, setSs] = useState('')
  const [birthday, setBirthday] = useState(timestampToDate(Date.now()))
  const [balance, setBalance] = useState(0)
  const [level, setLevel] = useState(ranks.USER)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [inputErrors, setInputErrors] = useState({})
  const [successMsg, setSuccessMsg] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [reject, setReject] = useState(false)
  const [memUser, setMemUser] = useState({})
  const { pageLoading, setPageLoading } = usePageAuth()
  const { logOutErrorHandler } = useContext(AuthContext)
  const [isAdmin, setIsAdmin] = useState(false)
  // const [email, setEmail] = useState('')
  // const [info, setInfo] = useState('')
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    async function fetchUser() {
      setPageLoading(true)
      try {
        // Mode edit only
        const user = await getUserDataAdmin(id)
        console.log(user, 'userTemplateModel')
        setName(user.name ?? '')
        setSurname(user.surname ?? '')
        // setEmail(user.email)
        setAccountNumber(user.accountNumber ?? '')
        setSs(user.ss ?? '')
        setBirthday(timestampToDate(user.birthday))
        // setInfo(user.info ?? '')
        setBalance(user.balance ?? 0)
        setLevel(user.level ?? ranks.USER)
        setPassword(user.password ?? '')
        setPhoneNumber(user.phoneNumber.split('+').join('') ?? '')
        // Mémorisation des valeurs de départ
        setMemUser(user)
      } catch (error) {
        if (logOutErrorHandler(error)) {
          return
        }
        setErrorMsg(error.message)
      }
      setPageLoading(false)
    } // Récupération des données user
    const isAdminToken = getTokenIsAdmin()
    // console.log({ isAdminToken })
    setIsAdmin(isAdminToken)
    if (!id && !isAdminToken) {
      setReject(true)
      setErrorMsg("Vous n'êtes pas autorisé")
    }
    if (id) {
      fetchUser()
    }
  }, [id]) // gestion de page

  const handleChangeName = (e) => setName(e.target.value)
  const handleChangeSurname = (e) => setSurname(e.target.value)
  // const handleChangeName = (e) => setName(forceInput(name, e.target.value, " -'", 50))
  // const handleChangeSurname = (e) => setSurname(forceInput(surname, e.target.value, " -'", 50))
  // const handleChangeEmail = (e) => setEmail(e.target.value)
  const handleChangeAccountNumber = (e) =>
    setAccountNumber(forceInputNumber(accountNumber, e.target.value, 8))
  const handleChangePassword = (e) => setPassword(forceInputNumber(password, e.target.value, 8))
  const handleChangeSs = (e) => setSs(forceInputNumber(ss, e.target.value, 15))
  const handleChangePhone = (e) =>
    setPhoneNumber(forceInputNumber(phoneNumber, e.target.value.replace('+', ''), 12))
  const handleChangeLevel = (e) => setLevel(e.target.value)
  const handleChangeBirthday = (e) => {
    setBirthday(e.target.value)
  }
  // const handleChangeInfo = (e) => setInfo(e.target.value)
  const handleChangeBalance = (e) => setBalance(forceInputNumber(balance, e.target.value, 12)) // centime ?

  function canSubmit() {
    // possible succès de publier
    const errors = {
      name: '',
      surname: '',
      accountNumber: '',
      password: '',
      ss: '',
      phone: '',
      level: '',
      birthday: '',
      balance: '',
      // email: '',
    }

    if (name === '') {
      errors.name = 'Ce champ ne doit pas être vide'
    }
    // else if (!isAlpha(name, 'fr-FR', { ignore: " -'" })) {
    //   errors.name = 'Ce champ contient des caractères non autorisés'
    // }
    if (surname === '') {
      errors.surname = 'Ce champ ne doit pas être vide'
    }
    // else if (!isAlpha(surname, 'fr-FR', { ignore: " -'" })) {
    //   errors.surname = 'Ce champ contient des caractères non autorisés'
    // }
    if (accountNumber === '') {
      errors.accountNumber = 'Ce champ ne doit pas être vide'
    }
    if (!id || changePassword) {
      if (password.length !== 8) {
        errors.password = 'Le mot de passe doit contenir 8 caractères'
      }
    }
    // if (ss === '') {
    //   errors.ss = 'Ce champ ne doit pas être vide'
    // }
    if (phoneNumber === '') {
      errors.phoneNumber = 'Ce champ ne doit pas être vide'
    }
    // else if (!isMobilePhone('+' + phoneNumber, 'fr-FR')) {
    //   errors.phoneNumber = 'Ce champ doit contenir un numéro de téléphone valide'
    // }
    if (!Object.values(ranks).includes(level)) {
      // si en dehors des valeurs de nos rangs, le rang est invalide
      errors.level = 'Le rang est invalide'
    }
    if (balance === '') {
      errors.balance = 'Ce champ ne doit pas être vide'
    }
    // Validation birthday - Info n'a pas de validation - Email a validé seulement si non vide - Validation Password spécial à mettre en place seulement si ID existe
    setInputErrors(errors)
    let success = Object.values(errors).every((el) => el.length === 0) // passe toutes les valeurs en vérifiant que ce sont des bien chaînes de caractère vides (bouléen vrai)
    console.log({ errors })
    return success
  }

  async function handleSubmit(e) {
    e.preventDefault()
    // console.log(canSubmit(), inputErrors)
    if (!canSubmit()) {
      return
    }
    const body = {
      name,
      surname,
      accountNumber,
      ss,
      // password,
      phoneNumber: '+' + phoneNumber,
      level,
      // Division par mille pour eviter un bogue cote backend
      birthday: new Date(birthday).getTime() / 1000,
      balance: parseInt(balance) /*, email*/,
    }
    // console.log({ changePassword })
    if (!id || changePassword) body.password = password
    // if (!id) delete body.level
    if (!id) {
      // Envoi pour création utilisateur (POST)
      try {
        const response = await createUser(body)

        if (response.status === 200) {
          setSuccessMsg(
            `L'utilisateur ${response.data.account.surname} ${response.data.account.name} a été créé`
          )
          setTimeout(() => {
            navigate('/liste-utilisateurs')
          }, 3000)
        } else {
          throw response.data.error
        }
      } catch (error) {
        if (error.response.data.error === 'account_id') {
          setErrorMsg('Numéro de compte déjà utilisé')
        } else if (error.response.data.error === 'phone_number') {
          setErrorMsg('Numéro de téléphone invalide')
        } else {
          setErrorMsg(error.message)
        }
      }
    } else {
      // Envoi pour modification utilisateur (PUT)
      try {
        const newBody = keepChangesOnly(body)
        // return
        const response = await changeUserAccount(id, newBody)

        if (response.status === 200) {
          setSuccessMsg(
            `L'utilisateur ${response.data.surname} ${response.data.name} a été modifié`
          )
          setTimeout(() => {
            navigate('/liste-utilisateurs')
          }, 3000)
        } else {
          throw response.data.error
        }
      } catch (error) {
        setErrorMsg(error.message)
      }
    }
  }

  function keepChangesOnly(body) {
    const entries = Object.entries(body).filter(([key]) => {
      return body[key] !== memUser[key]
    })
    const newBody = Object.fromEntries(entries)
    // console.log(Object.fromEntries(newBody)); // console.log(memUser.birthday, '1')
    if (memUser.birthday === newBody.birthday * 1000) {
      delete newBody.birthday
    }
    return newBody
  }

  return pageLoading ? (
    <main className="page-msg">
      <p>Chargement..</p>
    </main>
  ) : errorMsg ? (
    <main className="page-msg">
      <p className="text-align-c">{errorMsg}</p>
      <button
        className="back-button-edit"
        onClick={() => {
          if (reject) {
            navigate('/liste-utilisateurs')
          } else {
            setErrorMsg('')
          }
        }}
      >
        {/* <button className="back-button-edit" onClick={() => navigate('/liste-utilisateurs')}> */}
        Retour
      </button>
    </main>
  ) : successMsg ? (
    <main className="page-msg">
      <p>{successMsg}</p>
    </main>
  ) : (
    <>
      <h2>Informations du profil :</h2>
      <main className="personnal-profile">
        <div className="text-blocs">
          <div className="all-blocs">
            <div className="user-edit">
              <div>
                <Link to="/liste-utilisateurs">
                  <button className="back-btn">
                    <FiSkipBack />
                  </button>
                </Link>
                <RenderFormUser
                  handleSubmit={handleSubmit}
                  surname={surname}
                  name={name}
                  accountNumber={accountNumber}
                  password={password}
                  setPassword={setPassword}
                  changePassword={changePassword}
                  setChangePassword={setChangePassword}
                  balance={balance}
                  level={level}
                  birthday={birthday}
                  ss={ss}
                  // info={info}
                  timestampToDate={timestampToDate}
                  phoneNumber={phoneNumber}
                  handleChangeName={handleChangeName}
                  handleChangeSurname={handleChangeSurname}
                  handleChangeAccountNumber={handleChangeAccountNumber}
                  handleChangePassword={handleChangePassword}
                  handleChangeBirthday={handleChangeBirthday}
                  handleChangeBalance={handleChangeBalance}
                  handleChangeSs={handleChangeSs}
                  handleChangePhone={handleChangePhone}
                  handleChangeLevel={handleChangeLevel}
                  // handleChangeInfo={handleChangeInfo}
                  isAdmin={isAdmin}
                  id={id}
                  inputErrors={inputErrors}
                  MdGppGood={MdGppGood}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
