export const menuItems = {
  contact: {
    path: '/contact',
    img: process.env.PUBLIC_URL + '/images/icons/blanc_chat.png',
    name: 'Contact',
  },
  currency: {
    path: '/monnaie',
    img: process.env.PUBLIC_URL + '/images/icons/blanc_monnaie.png',
    name: 'Monnaie',
  },
  account: {
    path: '/compte',
    img: process.env.PUBLIC_URL + '/images/icons/blanc_synthese.png',
    name: 'Synthèse',
  },
  operation: {
    path: '/operation',
    img: process.env.PUBLIC_URL + '/images/icons/blanc_curseurs.png',
    name: 'Opérations',
  },
  beneficiaries: {
    path: '/beneficiaires',
    img: process.env.PUBLIC_URL + '/images/icons/blanc_compte.png',
    name: 'Bénéficiaires',
  },
  shop: {
    path: '/enseignes',
    img: process.env.PUBLIC_URL + '/images/icons/blanc_in_situ.png',
    name: 'Enseignes',
  },
  userlist: {
    path: '/liste-utilisateurs',
    img: process.env.PUBLIC_URL + '/images/icons/blanc_home.png',
    name: 'Profils',
  },
}
