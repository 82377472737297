import { useState } from 'react'
import '../../style/screens/Contact.scss'

export default function Contact() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [, setShowErrors] = useState(false)
  const [, setErrorMsgs] = useState([])
  const [, setMailSent] = useState(false)

  function clearForm() {
    setName('')
    setEmail('')
    setMessage('')
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setErrorMsgs([])
    setMailSent(false)

    let isValidEmail = false
    if (!name) {
      setErrorMsgs((errorMsgs) => [...errorMsgs, 'Le nom est obligatoire.'])
    }
    if (!email) {
      setErrorMsgs((errorMsgs) => [...errorMsgs, 'Le courriel est obligatoire.'])
    } else {
      if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
        setErrorMsgs((errorMsgs) => [...errorMsgs, 'Invalid email address.'])
      } else {
        isValidEmail = true
      }
    }
    if (!message) {
      setErrorMsgs((errorMsgs) => [...errorMsgs, 'Un message est obligatoire.'])
    }
    if (!name || !email || !isValidEmail || !message) {
      setShowErrors(true)
      return false
    } else {
      setShowErrors(false)
      // Use API here
      setMailSent(true)
      clearForm()
    }
  }

  return (
    <>
      <main className="contact-page">
        <form
          className="cmcfui1 text-blocs"
          action=""
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          autoComplete="off"
        >
          <h3 className="cmhead">Contactez-nous</h3>
          <p>Courriel :</p>
          <p>information@le-franc-libre.fr</p>
          <p>Courrier :</p>
          <p>FCSP - LE FRANC LIBRE, 8 square de la Tour Maubourg, 75007 PARIS</p>
          <p>Chèque : Libellé "FCSP"</p>
          <p>Virement à venir...</p>
          <a href="https://www.le-franc-libre.fr/">
            Plus d'informations sur le site du Franc libre
          </a>
          <p></p>
        </form>
      </main>
    </>
  )
}
