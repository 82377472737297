export default class Shop {
  id: string
  name: string
  position: number[]
  phoneNumber: string
  contactName: string
  email?: string
  info: string

  constructor(data: any) {
    this.id = data.id
    this.name = data.name
    this.position = data.position
    this.phoneNumber = data.phoneNumber
    this.contactName = data.contactName
    this.email = data.email
    this.info = data.info
  }
}
