export default class Transaction {
  id: string
  name: string // Raison de la transaction en libellé
  from: string
  senderFullName: string
  receiverFullName: string
  to: string
  amount: number
  // status: string = 'SUCCESS'
  time: Date

  constructor(data: any) {
    this.id = data.id
    this.name = data.name
    this.from = data.from
    this.senderFullName = data.senderFullName
    this.receiverFullName = data.receiverFullName
    this.to = data.to
    this.amount = data.amount
    // this.status = data.status
    this.time = data.time
  }
}

//   "id": "633c0e9390d226283dc38654",
//   "name": "Don test admin to user",
//   "from": "631ccdf4930f0a562d6661b4",
//   "senderFullName": "Florian ROUANET",
//   "receiverFullName": "Florian UserTest",
//   "to": "6334a30af69df021f91de3ca",
//   "amount": 60,
//   "status": "SUCCESS",
//   "time": 1664880275703
