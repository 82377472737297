import isNumeric from 'validator/es/lib/isNumeric'
import isAlpha from 'validator/lib/isAlpha'

export function forceInputNumber(oldValue, newValue, limit = -1) {
  newValue = newValue.replace(/ /g, '')
  if (isNumeric(newValue, { no_symbols: true }) || newValue === '') {
    return limit >= 0 ? newValue.substring(0, limit) : newValue // on le renvoie si critères remplis
  }
  return limit >= 0 ? oldValue.substring(0, limit) : oldValue
}

export function forceInputAlpha(oldValue, newValue, ignore = '', limit = -1) {
  if (isAlpha(newValue, 'fr-FR', { ignore }) || newValue === '') {
    return limit >= 0 ? newValue.substring(0, limit) : newValue // on le renvoie si critères remplis
  }
  return limit >= 0 ? oldValue.substring(0, limit) : oldValue
}

export function forceInput(oldValue, newValue, ignore = '', limit = -1) {
  if (isAlpha(newValue, 'fr-FR', { ignore }) || newValue === '') {
    return limit >= 0 ? newValue.substring(0, limit) : newValue // on le renvoie si critères remplis
  }
  return limit >= 0 ? oldValue.substring(0, limit) : oldValue
}
