import { baseUrl } from '../constants/constants'
import axios from 'axios'

export const createBeneficiary = async (body) => {
  try {
    const response = await axios.post(`${baseUrl}api/account/contact/new`, body, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    })
    if (response.data.error) {
      console.log('Erreur envoyée par le serveur', response.data.error)
    }
    return response
  } catch (error) {
    throw error
  }
} // POST

export const deleteBeneficiary = async (id) => {
  try {
    const response = await axios.delete(`${baseUrl}api/account/contact/delete/${id}`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    })
    if (response.data.error) {
      console.log('Erreur envoyée par le serveur', response.data.error)
    }
    return response
    // Pour supprimer un contact : /account/contact/delete/{id} et l'id correspond à l'id du contact (cet id est disponible quand tu appelle la route 'account', dans la liste des contacts)
  } catch (error) {
    throw error
  }
} // DELETE
