import { baseUrl } from '../constants/constants'
import axios from 'axios'
import Shop from '../models/Shop.ts'

export const getShopList = async () => {
  try {
    const response = await axios.get(`${baseUrl}api/shop/`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    })
    return response.data.map((shopData) => new Shop(shopData))
  } catch (error) {
    throw error
  }
}
