import { useContext, useEffect } from 'react'
import { AuthContext } from '../../utils/providers/AuthContext'
import { BiTimer } from 'react-icons/bi'
import { SessionContext } from '../../utils/providers/SessionProvider'
import '../../style/UI/PopUpSessionTimeout.scss'

export default function PopUpSessionTimeout() {
  const { refreshCountdown, setRefreshCountdown, startSession } = useContext(SessionContext)
  const { logOut } = useContext(AuthContext)

  useEffect(() => {
    if (refreshCountdown === 0) {
      setRefreshCountdown(-1)
      logOut()
    } else {
      decreaseTimer()
    }
  }, [refreshCountdown])

  function decreaseTimer() {
    setTimeout(() => {
      setRefreshCountdown((oldValue) => Math.max(oldValue - 1, -1))
    }, 1000)
  }

  function handleLogOut(e) {
    e.preventDefault()
    setRefreshCountdown(-1) // ferme la popup
    logOut()
  }

  function handleContinue() {
    setRefreshCountdown(-1)
    startSession()
  }

  return (
    <>
      <div className="popup-overlay"></div>
      <div className="popup-content">
        <p className="popup-content__msg">Sans action de votre part vous serez déconnecté</p>
        <div className="popup-content__msg-timer popup-content__animation">
          <BiTimer />
          <p className="">{refreshCountdown} sec.</p>
        </div>
        <div className="popup-content__button">
          <button onClick={handleLogOut}>Me déconnecter</button>
          <button onClick={handleContinue}>Prolonger ma session</button>
        </div>
      </div>
    </>
  )
}
