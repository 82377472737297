import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import {
  AuthContext,
  getTokenIsAdmin,
  getTokenIsLocalAdmin,
} from '../../utils/providers/AuthContext'
import { shuffleNumbers } from '../../utils/functions/shuffleNumbers'
import { loginUser } from '../../data/services/apiUser'
import RenderFormConnection from '../../utils/renders/RenderFormConnection'
import isNumeric from 'validator/es/lib/isNumeric'
import HelpContainer from '../UI/HelpContainer'
import '../../style/screens/Connection.scss'

const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j']

export default function Connection() {
  const [digits] = useState(shuffleNumbers([...Array(10).keys()]))
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [errorLogin, setErrorLogin] = useState('')
  const [errorPassword, setErrorPassword] = useState('')
  const { setIsConnected, setIsAdmin } = useContext(AuthContext)
  const [errorMsg, setErrorMsg] = useState('')
  const { pageLoading } = usePageAuth(true)
  let navigate = useNavigate()

  function handleClickDigit(e) {
    e.preventDefault()
    setPassword((password + e.target.name).substring(0, 8))
  }

  function handleClearPassword(e) {
    e.preventDefault()
    setPassword('')
  }

  function handleChangeLogin(e) {
    e.preventDefault()
    if (isNumeric(e.target.value, { no_symbols: true }) || e.target.value === '') {
      setLogin(e.target.value.substring(0, 8))
      // !isNaN(Number(e.target.value)) && e.target.value === e.target.value.trim())
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    // Réinitialisation des champs d'erreur
    setErrorLogin('')
    setErrorPassword('')
    let success = true
    if (login.length !== 8) {
      success = false
      setErrorLogin("Le nombre de chiffres pour l'identifiant est insuffisant")
    }
    if (password.length !== 8) {
      success = false
      setErrorPassword('Le nombre de chiffres pour le mot de passe est insuffisant')
    }
    if (success === false) {
      return
    }

    const decrypt = password
      .split('')
      .map((char) => digits[letters.indexOf(char)])
      .join('')

    try {
      const token = await loginUser(login, decrypt)
      localStorage.setItem('accessToken', token)
      setIsConnected(true)
      // startSession()
      const checkAdmin = getTokenIsAdmin()
      setIsAdmin(checkAdmin)
      const checkLocalAdmin = getTokenIsLocalAdmin()
      setIsAdmin(checkLocalAdmin)

      // On redirige en fonction du rang
      if (checkAdmin || checkLocalAdmin) {
        navigate('/liste-utilisateurs')
      } else {
        navigate('/compte')
      }
    } catch (error) {
      if (error.response.status === 403) {
        setErrorMsg('Identifiant ou mot de passe invalide')
      } else {
        setErrorMsg(error.message)
      }
    }
  }

  return pageLoading ? (
    <main className="page-msg">
      <p>Chargement..</p>
    </main>
  ) : errorMsg ? (
    <main className="page-msg">
      <p>{errorMsg}</p>
      <button className="back-button-edit" onClick={() => setErrorMsg('')}>
        Retour
      </button>
    </main>
  ) : (
    <>
      <div className="fresco-container">
        <img
          src={process.env.PUBLIC_URL + '/images/elements/FRESQUE_BD_LG.jpg'}
          width="100%"
          alt="fesque,franc libre argent dématérialisé, banque en ligne, retrait, transfert"
        />
      </div>
      <div className="fresco-texts">
        <img
          src={process.env.PUBLIC_URL + '/images/titles/Logo_Banque_de_France_Libre®2.png'}
          alt="banque france libre, résilience, résistance"
        />
      </div>

      <main className="login-page">
        <div className="texts-blocs">
          <div className="gap">
            <img
              src={process.env.PUBLIC_URL + '/images/icons/bleu_mar_cadenas_ferm.png'}
              height="40px"
              width="40px"
              className="space-icon"
              alt="connexion du compte, securisation"
            />
            <p className="space-text less-spacement">MON ESPACE</p>
            <RenderFormConnection
              handleSubmit={handleSubmit}
              handleChangeLogin={handleChangeLogin}
              handleClickDigit={handleClickDigit}
              login={login}
              errorLogin={errorLogin}
              errorPassword={errorPassword}
              digits={digits}
              letters={letters}
              password={password}
              handleClearPassword={handleClearPassword}
            />
          </div>
          <HelpContainer
            links={[
              {
                img: process.env.PUBLIC_URL + '/images/icons/bleu_mar_monnaie.png',
                text: 'Monnaie',
                path: '/monnaie',
              },
              {
                img: process.env.PUBLIC_URL + '/images/icons/bleu_mar_hotline.png',
                text: "Besoin d'aide ? Contactez-nous",
                path: '/contact',
              },
            ]}
          />
        </div>
      </main>
    </>
  )
}
