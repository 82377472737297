import { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext, getTokenIsLocalAdmin } from '../../utils/providers/AuthContext'
import { usePageAuth } from '../../utils/hooks/usePageAuth'
import { getUserDataSelf } from '../../data/services/apiUser'
import { getUserTransactions } from '../../data/services/apiTransaction'
import { formatDate } from '../../utils/functions/timeDate'
import Transaction from '../UI/Transaction'
import HelpContainer from '../UI/HelpContainer'
import LoadingSpinner from '../UI/LoadingSpinner'
import '../../style/screens/Operation.scss'

export default function Operation() {
  const [errorMsg, setErrorMsg] = useState('')
  const [reject, setReject] = useState(false)
  const [user, setUser] = useState([])
  const [transactions, setTransactions] = useState([])
  const { pageLoading, setPageLoading } = usePageAuth()
  const { logOutErrorHandler } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    async function fetchData() {
      setPageLoading(true)
      try {
        let result
        result = await getUserDataSelf()
        setUser(result)
        result = await getUserTransactions()
        setTransactions(result)
      } catch (error) {
        if (logOutErrorHandler(error)) {
          return
        }
        setErrorMsg(error.message)
      }
      setPageLoading(false)
    }

    if (getTokenIsLocalAdmin()) {
      setErrorMsg("Vous n'êtes pas autorisé")
      setReject(true)
    } else {
      fetchData()
    }
  }, [])

  return pageLoading ? (
    <main className="page-msg">
      <LoadingSpinner />
    </main>
  ) : errorMsg ? (
    <main className="page-msg">
      <p>{errorMsg}</p>
      <button
        className="back-button-edit"
        onClick={() => {
          if (reject) {
            navigate('/liste-utilisateurs')
          } else {
            setErrorMsg('')
          }
        }}
      >
        Retour
      </button>
    </main>
  ) : (
    <>
      <HelpContainer
        links={[
          {
            img: process.env.PUBLIC_URL + '/images/icons/bleu_mar_synthese.png',
            text: 'Mon compte',
            path: '/compte',
          },
          {
            img: process.env.PUBLIC_URL + '/images/icons/bleu_mar_compte.png',
            text: 'Mes bénéficiaires',
            path: '/beneficiaires',
          },
        ]}
      />
      <main className="personnal-operation">
        <div className="texts-blocs">
          <h2 className="operation-span-text">Mes dernières opérations</h2>

          <div>
            <Transaction />
          </div>

          <div className="operation-account">
            <div className="grid-row head">
              <div className="grid-cell">Date</div>
              <div className="grid-cell">Nature de l'opération</div>
              <div className="grid-cell">Montant</div>
            </div>

            {transactions.map((transaction, index) => {
              const userIsSender = user.id === transaction.from
              const userIsReceiver = user.id === transaction.to
              if (index < 15) {
                return (
                  <div className="grid-row" key={transaction.id}>
                    <div className="grid-cell">{formatDate(transaction.time, 'D/M/YY')}</div>

                    <div className="grid-cell">
                      <div className="mobile date">{formatDate(transaction.time, 'D/M/YY')}</div>
                      <img
                        className="operation-icon"
                        src={process.env.PUBLIC_URL + '/images/icons/bleu_mar_cellphone.png'}
                        alt="paiement, carte bancaire, carte bleue"
                      />
                      {transaction.name} {userIsSender && 'à ' + transaction.receiverFullName}
                      {userIsReceiver && 'de ' + transaction.senderFullName}
                      <div className={`mobile amount ${userIsReceiver ? 'plus' : 'minus'}`}>
                        {userIsSender && '-'}
                        {userIsReceiver && '+'}
                        {transaction.amount}
                      </div>
                    </div>

                    <div className={`grid-cell ${userIsReceiver ? 'plus' : 'minus'}`}>
                      {userIsSender && '-'}
                      {userIsReceiver && '+'}
                      {transaction.amount}
                    </div>
                  </div>
                )
              }
            })}
          </div>
        </div>
      </main>
      <HelpContainer
        links={[
          {
            img: process.env.PUBLIC_URL + '/images/icons/bleu_mar_in_situ.png',
            text: 'Où utiliser les francs libres ?',
            path: '/enseignes',
          },
          {
            img: process.env.PUBLIC_URL + '/images/icons/bleu_mar_hotline.png',
            text: "Besoin d'aide ? Contactez-nous",
            path: '/contact',
          },
        ]}
      />
    </>
  )
}
