import { useContext } from 'react'
import { AuthContext } from '../../utils/providers/AuthContext'
import '../../style/UI/PopUpLogOut.scss'

export default function LogOutPopUp({ onClose }) {
  const { logOut } = useContext(AuthContext)
  // Me déconnecter, Prolonger ma session
  function handleConfirm(e) {
    e.preventDefault()
    onClose(e)
    logOut()
  }

  return (
    <>
      <div className="popup-overlay" onClick={onClose}></div>
      <div className="popup-container">
        <p className="popup-container__msg">Êtes-vous certain de vouloir vous déconnecter ?</p>
        <div className="popup-container__button">
          <button onClick={handleConfirm}>Oui</button>
          <button onClick={onClose}>Non</button>
        </div>
      </div>
    </>
  )
}
